import React from 'react';
import styled from 'styled-components';
import { graphql, StaticQuery } from 'gatsby';
import { IGatsbyImageData } from 'gatsby-plugin-image';

import Layout from 'src/Layout';

import Container from 'src/Components/ContentContainer';
import News from 'src/Components/News';
import SEO from 'src/Components/SEO';

import * as Base from 'src/page-styles/base.styled';

const NewsWrapper = styled.div`
  @media only screen and (min-width: 1024px) {
    display: grid;
    gap: 4rem 2rem;
    grid-template-columns: 1fr 1fr;
  }

  @media only screen and (min-width: 1200px) {
    grid-template-columns: 1fr 1fr 1fr;
  }
`;

interface Props {
  data: {
    allMarkdownRemark: {
      edges: Array<{
        node: {
          parent: {
            name: string;
            relativeDirectory: string;
          };
          frontmatter: {
            title: string;
            description: string;
            date: string;
            display_image: IGatsbyImageData;
          };
        };
      }>;
    };
  };
}

const PressReleases: React.FunctionComponent<Props> = ({ data }: Props) => {
  const { allMarkdownRemark } = data;
  const { edges: prs } = allMarkdownRemark;

  return (
    <>
      <SEO
        title="Press releases | Saber Astronautics"
        description="At Saber, we take pride in our work and love to share our achievements. Find out some projects we've been up to."
        url="https://www.saberastro.com/press-releases"
      />
      <Layout>
        <Container.Dark data-cy="press-releases">
          <Base.MenuGap />
          <Base.Heading>PRESS RELEASES</Base.Heading>
          <Base.Gap />
          <NewsWrapper>
            {prs.map((pr) => {
              const { title, date, display_image } = pr.node.frontmatter;
              const { name, relativeDirectory } = pr.node.parent;

              return (
                <News
                  key={title}
                  href={`/${relativeDirectory}/${name}/`}
                  title={title}
                  date={date}
                  displayImage={display_image}
                />
              );
            })}
          </NewsWrapper>
        </Container.Dark>
      </Layout>
    </>
  );
};

export default (): JSX.Element => (
  <StaticQuery
    query={graphql`
      query NewsQuery {
        allMarkdownRemark(
          sort: { fields: frontmatter___date, order: DESC }
          filter: { frontmatter: { type: { eq: "pr" } } }
        ) {
          edges {
            node {
              parent {
                ... on File {
                  name
                  relativeDirectory
                }
              }
              frontmatter {
                title
                date
                display_image {
                  childImageSharp {
                    gatsbyImageData(
                      layout: FULL_WIDTH
                      placeholder: BLURRED
                      formats: [AUTO, WEBP, AVIF]
                    )
                  }
                }
              }
            }
          }
        }
      }
    `}
    render={(data) => <PressReleases data={data} />}
  />
);
